import React from 'react';
import {
    Modal, ModalBody
} from 'reactstrap';
import PageLoading from '../shared/PageLoading/PageLoading';
const ProcessingModal = ({ isProcessing }) => {
    return (
        <Modal isOpen={isProcessing} className="md" >
            <ModalBody>
                <PageLoading
                    title={"Processing, please wait..."}
                    text={"We are processing your payment. Do not refresh or close your browser."}
                    height={300}
                    color={"secondary"}
                />
            </ModalBody>
        </Modal>
    )
}
export default ProcessingModal;