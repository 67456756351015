import React, { Component } from 'react';
import {
    Row, Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import styles from './Entitlement.module.css';
import '../css/TheftLoss.css';
import classNames from 'classnames';
import SimpleReactValidator from 'simple-react-validator';
import SaveModal from '../../../../components/modals/SaveModal';
import moment from 'moment';
import Calendar from 'react-calendar';
import Cleave from 'cleave.js/react';
import { ReactComponent as CalendarIcon } from '../../../../assets/images/calendar.svg';
import { updatePartnerCase } from '../../../../helpers';
import DocumentTitle from 'react-document-title';
import { isMobile } from 'react-device-detect';
import { UseTranslationInClassComponent } from '../../../contexts/LanguageTranslation/UseTranslationInClassComponent';
import IntakeLossButtons from './IntakeLossButtons';
import IncidentDesc from '../../Apple/IntakeLoss/IncidentDesc';
import { appleAffirmationsByState, defaultEnglishAffirmation, getAppleAffirmationStatement } from './AppleAffirmationsByState';
import { ContractContext } from '../../../contexts/ContractContext';

let cardTitle = styles.cardTitle;
let dateInput = styles.dateInput;
const formError = styles.formError;
const textError = styles.textError;
let mw275 = styles.mw275;
let mb0 = "mb-0";
let formControl = "form-control";
let maxLength = styles.maxLength;
let col4 = "col-4";
let radioControlsLabel = "radio-controls-label";
let w100 = styles.w100;

export class Entitlement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            symptom: '',
            coverageId: null,
            contractId: this.props.contractId,
            tempDate: '',
            showCalendar: false,
            incidentDate: '',
            incidentDescription: '',
            clientId: this.props.history.location.state.entitlementDetails.clientId,
            claimNumber: this.props.history.location.state.entitlementDetails.claimNumber,
            caseNumber: this.props.history.location.state.entitlementDetails.caseNumber,
            claimId: this.props.claimId,
            contractAssetId: this.props.contractAssetId,
            continuing: false,
            statusFlag: this.props.statusFlag,
            validationFired: false,
            affirmed: '',
            pageTitle: 'Incident Info | AIG AppleCare+ with Theft and Loss Claims',
            claimSetupDate: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleCoverageChange = this.handleCoverageChange.bind(this);
        this.handleCalendarDate = this.handleCalendarDate.bind(this);
        this.handleCleaveDate = this.handleCleaveDate.bind(this);
        this.openCalendar = this.openCalendar.bind(this);
        this.affirmationChange = this.affirmationChange.bind(this);
        this.checkIfError = this.checkIfError.bind(this);
        this.continue = this.continue.bind(this);
        this.keepOnPage = this.keepOnPage.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.validator = new SimpleReactValidator({
            validators: {
                affirmation: {
                    rule: (val, params, validator) => {
                        return this.state.affirmed === "affirmed";
                    },
                    required: true
                },
            },
            element: message => <div className="errorMsg" role="alert">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
        });
    }

    onSaveForLaterAction = e => {
        e.preventDefault();
        console.log("save");
        this.setState({ showConfirmation: true });
    }

    onSaveCancel = e => {
        e.preventDefault();
        this.setState({ showConfirmation: false });
        document.getElementById('later-id').focus()
    }


    componentDidMount() {
        window.addEventListener('beforeunload', this.keepOnPage);
        let claim = this.props.history.location.state.claim;
        var setupDate = new Date(claim.claimSetupDate);
        this.setState({
            claimSetupDate: claim.claimSetupDate == null ? '' : setupDate
        });
        let feature = claim.featureClaimModel;
        let featureIndex = feature.length - 1;
        if (feature && feature.length > 0) {
            fetch('api/contract/GetCoverageByContractAssetCoverageId/'.concat(feature[0].contractAssetCoverageId).concat('/').concat(this.props.history.location.state.entitlementDetails.contract.contractAssetModel[0].contractAssetId).concat('/apple'))
                .then(res => res.json())
                .then(data => {
                    var lossDate = new Date(claim.lossDate);
                    console.log(data);
                    this.setState({
                        tempDate: claim.lossDate == null ? '' : moment(lossDate).format("MM/DD/YYYY"),
                        incidentDate: claim.lossDate == null ? '' : lossDate,
                        incidentDescription: claim.incidentDescription || '',
                        coverageId: data.coverageId,
                        affirmed: claim.affirmationFlag ? "affirmed" : ''
                    });
                });

            //var cId = feature[0].contractAssetCoverageId == 1 ? 13 : 14;            
        }
        console.log(claim);
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    affirmationChange = () => {
        if (this.state.affirmed === '') {
            this.setState({ affirmed: "affirmed" });
        }
        else {
            this.setState({ affirmed: '' });
        }
    }
    handleCoverageChange = e => {
        console.log('hu', e.target)
        this.setState({
            coverageId: parseInt(e.target.value)
        });
    }


    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let { name, value, id } = e.target;

            let checked = false;

            switch (id) {
                case "reach-skip-nav":
                    checked = document.getElementById('carrier1').checked;
                    break;
                case "carrier2Div":
                    checked = document.getElementById('carrier2').checked;
                    break;

                case "otherCarrierCheckboxDiv":
                    checked = document.getElementById('otherCarrier').checked;
                    break;
                default:
                    checked = e.target.checked;
            }



            if (checked === false) {
                switch (id) {
                    case "otherCarrier":
                    case "otherCarrierCheckboxDiv":
                        value = "other";
                        break;
                    case "reach-skip-nav":
                        value = 13;
                        break;
                    case "carrier2Div":
                        value = 14;
                        break;

                }
            } else {
                value = null;
            }

            this.setState({
                coverageId: value,
                validated: false,
            });
        }
    }

    handleCleaveDate = (e, dateFormat = "MM/DD/YYYY") => {
        let cleaveDate = e.target.value;
        let date = new Date(moment(cleaveDate).format(dateFormat));

        if (!isNaN(date.getTime())) {
            this.setState({
                tempDate: cleaveDate,
                incidentDate: date
            });
        }
        else {
            this.setState({
                tempDate: cleaveDate
            });
        }

        const dayFirstRegex = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
        const monthFirstRegex = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
        const localizedRegex = dateFormat === "DD/MM/YYYY" ? dayFirstRegex : monthFirstRegex

        if (this.validator.helpers.testRegex(cleaveDate, localizedRegex)) {
            this.openCalendar(false);
        }
    }

    handleCalendarDate = (e, momentJsFormat) => {

        this.setState({
            tempDate: moment(e).format(momentJsFormat),
            incidentDate: e
        });

        this.openCalendar(false);
    }

    openCalendar = (e) => {
        //e.preventDefault();

        this.setState({
            showCalendar: e
        })
    }

    checkIfError = field => {
        var messages = this.validator.getErrorMessages();
        for (var i = 0; i < messages.length; i++) {
            console.log(messages[i]);
        }

    }

    continue = () => {
        this.setState({ validationFired: true });

        this.openCalendar(false);

        if (this.validator.allValid()) {
            this.createFeatureClaimAndRunRules();
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    createFeatureClaimAndRunRules() {
        console.log("enter", this.props.history.location.state.entitlementDetails, this.state.clientId, this.state.isSaveForLater);
        if (this.validator.allValid() || this.state.isSaveForLater) {
            let entitlementDetails = this.props.history.location.state.entitlementDetails;
            this.setState({ continuing: true });

            //update partner case
            updatePartnerCase(entitlementDetails.claimNumber, "ASCH", this.state.clientId);

            let claim = this.props.history.location.state.claim;
            console.log(claim, this.props);
            claim.incidentDescription = this.state.incidentDescription;
            claim.lossDate = this.state.incidentDate;
            claim.coverageId = this.state.coverageId;
            claim.affirmationFlag = this.state.affirmed === "affirmed";

            if (this.state.coverageId == null && this.state.isSaveForLater) {
                this.saveForLaterAction(claim, false);
                return;
            }

            fetch('api/claim/featureclaim/'.concat(entitlementDetails.claimId), {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    claimId: entitlementDetails.claimId,
                    coverageId: this.state.coverageId == null ? 1 : this.state.coverageId,
                    incidentDescription: this.state.incidentDescription,
                    incidentDate: this.state.incidentDate,
                    clientId: this.state.clientId,
                    contractAssetId: entitlementDetails.contractAsset.contractAssetId || entitlementDetails.contractAsset.contractAssetModel[0].contractAssetId,
                    affirmed: this.state.affirmed === "affirmed",
                    FeatureClaimStatus: this.state.isSaveForLater ? 3 : 2,
                    claimSetupDate: this.state.claimSetupDate
                })
            }).then(res => {
                if (res.ok) {
                    if (this.state.isSaveForLater) {
                        this.saveForLaterAction(claim, false);
                        return;
                    }
                    res.json().then(data => {
                        this.setState({ continuing: false });
                        console.log(this.props)
                        this.props.history.push('/vendor/apple/verifyinfo', {
                            entitlementDetails: {
                                caseNumber: entitlementDetails.caseNumber,
                                contract: entitlementDetails.contract,
                                contractAsset: entitlementDetails.contractAsset,
                                claimId: entitlementDetails.claimId,
                                claimNumber: entitlementDetails.claimNumber,
                                partyPhone: entitlementDetails.partyPhone,
                                party: entitlementDetails.party,
                                deductible: entitlementDetails.deductible,
                                incidentDescription: this.state.incidentDescription,
                                incidentDate: this.state.incidentDate,
                                coverageId: this.state.coverageId,
                                clientId: this.state.clientId,
                                countryCode: this.props.history.location.state.entitlementDetails?.countryCode ?? "us",
                                claimSetupDate: this.state.claimSetupDate,
                                shipToCountryCode: entitlementDetails?.shipToCountryCode

                            }, claim: this.props.history.location.state.claim
                        });
                    });
                }
                else {
                    this.setState({ continuing: false });
                }
            })
        }
        else {
            console.log(this.validator.getErrorMessages());
            this.validator.showMessages();
            this.forceUpdate();
        }
    }




    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.keepOnPage);
    }
    keepOnPage(e) {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';

        //alert("Closing");
        // return this.saveForLater(e);
        // if (this.validator.allValid()) {

        //if (!this.validator.allValid()) {
        //    return;
        //}
        let entitlementDetails = this.props.history.location.state.entitlementDetails;
        let headers = {
            type: 'application/json'
        };

        let claim = this.props.history.location.state.claim;
        console.log("closing");
        this.saveForLaterAction(claim, true);
    }

    saveForLater = (e) => {
        e && e.preventDefault();
        this.setState({ isSaveForLater: true }, () => { this.createFeatureClaimAndRunRules() });
    }

    saveForLaterAction = (claim, claimAbandoned) => {
        if (!claim) { return; }
        this.setState({ uploading: true });
        claim.lossDate = claim.lossDate == "" ? null : claim.lossDate;
        console.log(this.props, claim);

        fetch(`api/claim/SaveForLater/${claim.claimId}/apple/1/${claimAbandoned}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(claim)
        })
            .then(data => {
                if (data.status === 204) {
                }
                if (data.status === 200 && !claimAbandoned) {
                    this.props.history.push('/vendor/apple/confirmation', { statusType: "savedClaim", caseNumber: claim.clientClaimNumber });
                }
                this.setState({ uploading: false });
            });
    }

    render() {
        const {
            pageTitle,
            validationFired,
            coverageId,
            tempDate,
            incidentDescription
        } = this.state;

        const { data: { isChileApple } } = this.context;

        return (
            <DocumentTitle title={pageTitle}>
                <UseTranslationInClassComponent componentPathFromSrcFolder={'components/Vendor/Apple/IntakeLoss/Entitlement'}>
                    {international => {
                        const {
                            country,
                            language,
                            countryVars: {
                                date: {
                                    cleavePattern,
                                    delimiter,
                                    momentJsFormat
                                }
                            },
                            translations
                        } = international;
                        console.log(country, language, international);

                        const getAffirmation = () => {
                            const serviceState = this.props.history?.location?.state?.entitlementDetails?.party?.serviceAddress?.state

                            // prefix prepends all US-ENGLISH affirmations for states with their own affirmation statements.
                            const messagePrefix = "To complete your claim you affirm that the information you have provided regarding your claim is true and accurate: "
                            const stateMessage = appleAffirmationsByState[serviceState]
                            const joinedPrefixAndCustom = stateMessage && messagePrefix + stateMessage

                            switch (country) {
                                case ("AU"):
                                case ("AUS"):
                                    return (
                                        <span>
                                            I declare (i) I have read and accepted the terms of the claims 
                                            <a href="https://www.aig.com.au/privacy" target="blank"> privacy notice</a>;
                                            and (ii) the information provided to be true in every respect to the best of
                                            our knowledge and belief and that any information provided which is false or
                                            untrue may result in no coverage for the claim and or cancellation of cover.
                                        </span>
                                    )
                                case ("US"):
                                    return joinedPrefixAndCustom || defaultEnglishAffirmation
                                case ('DE'):
                                case ('ES'):
                                case ('FR'):
                                case ('IT'):
                                case ('GB'):
                                case ('UK'):
                                case ('CL'):
                                case ('NZ'):
                                case ('AT'):
                                case ('DK'):
                                case ('FI'):
                                case ('IE'):
                                case ('NL'):
                                case ('NO'):
                                case ('SE'):
                                case ('CH'):
                                default:
                                    return (translations.affirmation || defaultEnglishAffirmation)
                            }
                        }
                        return (
                            <>
                                <Form>
                                    <fieldset id={styles.symptom} className="form-group" role="group">
                                        <legend
                                            className={classNames(styles.cardTitle, !coverageId && validationFired && textError)}
                                            aria-label={translations.whatTypeOfIncidentAria || "Select an incident from the list"}
                                            for="symptomGroup"
                                        >
                                            {translations.whatTypeOfIncident || 'What type of incident did you experience?'}
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                        </legend>
                                        <Row className="formBlock">
                                            <Col >
                                                <Row className={styles.symptomGroup}>
                                                    <Col md="3" xs="6">
                                                        <div className="radioButton customRadio" role="radio" aria-checked={this.state.coverageId === 13 ? "true" : "false"} tabIndex="0" id="reach-skip-nav" onKeyPress={this.handleKeyDown}>
                                                            <Input
                                                                type="radio"
                                                                aria-label={translations.theftAria || "theft"}
                                                                name={"symptom" + this.props.claimId}
                                                                id={"carrier1"}
                                                                value={13}
                                                                onKeyPress={this.handleKeyDown}
                                                                checked={this.state.coverageId === 13}
                                                                onChange={this.handleCoverageChange}
                                                            />
                                                            <Label
                                                                id="theft"
                                                                className={classNames(radioControlsLabel, w100, !coverageId && validationFired && formError)}
                                                                for={"carrier1"}>
                                                                {translations.theft || 'Theft'}
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                    <Col md="3" xs="6">
                                                        <div className="radioButton customRadio" role="radio" aria-checked={this.state.coverageId === 14 ? "true" : "false"} tabIndex="0" id="carrier2Div" onKeyPress={this.handleKeyDown}>
                                                            <Input
                                                                type="radio"
                                                                aria-label={translations.lossAria || "loss"}
                                                                name={"symptom" + this.props.claimId}
                                                                id="carrier2"
                                                                value={14}
                                                                onKeyPress={this.handleKeyDown}
                                                                checked={this.state.coverageId === 14}
                                                                onChange={this.handleCoverageChange}
                                                            />
                                                            <Label id="loss"
                                                                className={classNames(radioControlsLabel, w100, !coverageId && validationFired && formError)}
                                                                for={"carrier2"}>
                                                                {translations.loss || 'Loss'}
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {this.validator.message(
                                                    "symptom",
                                                    this.state.coverageId,
                                                    "required",
                                                    {
                                                        messages:
                                                        {
                                                            required: translations.errors?.symptom || 'Symptom is required.',
                                                        }
                                                    }
                                                )}
                                            </Col>
                                        </Row>
                                    </fieldset>
                                    <div id="incidentDate" >
                                        <FormGroup>
                                            <Label for="incidentDateInput"
                                                className={classNames(styles.cardTitle, !tempDate && validationFired && textError)}
                                            >{translations.whenOccurred || 'When did it happen?'}
                                                <span aria-hidden="true" className="required-indicator">*</span>
                                            </Label>
                                            <Row className="formBlock">
                                                {!isMobile ? (
                                                    <Col className={classNames(styles.mw275, 'd-sm-block', 'd-md-block', 'd-lg-block', 'd-xl-block')}>
                                                        <div className={classNames("input-group")}
                                                            aria-required="true"
                                                            aria-current="date"
                                                            aria-label={moment().format("MMM Do YYYY")}

                                                        >
                                                            <Cleave
                                                                onFocus={() => this.openCalendar(true)}
                                                                aria-current="date"
                                                                aria-label={moment().format("MMM Do YYYY")}
                                                                inputMode="numeric"
                                                                className={classNames(formControl, dateInput, !tempDate && validationFired && formError)}
                                                                name="incidentDate"
                                                                id="incidentDateInput"
                                                                value={this.state.tempDate}
                                                                onChange={(e) => this.handleCleaveDate(e, momentJsFormat)}
                                                                onKeyDown={(e) => e.keyCode === 27 && this.openCalendar(false)}
                                                                options={{
                                                                    date: true,
                                                                    delimiter: delimiter,
                                                                    datePattern: cleavePattern,
                                                                    dateMax: moment().format('YYYY-MM-DD'),
                                                                    dateMin: moment().subtract(1, 'year').format('YYYY-MM-DD')
                                                                }}
                                                            />
                                                            <CalendarIcon role="button" tabIndex={0} className={classNames(styles.datePicker, !tempDate && validationFired && formError)} name="calendarIcon" onClick={() => this.openCalendar(!this.state.showCalendar)} />
                                                            {this.state.showCalendar &&
                                                                <Calendar
                                                                    name="incidentDate"
                                                                    aria-current="date"
                                                                    aria-label={moment().format("MMM Do YYYY")}
                                                                    autocomplete="off"
                                                                    value={this.state.incidentDate}
                                                                    onChange={(e) => this.handleCalendarDate(e, momentJsFormat)}
                                                                    maxDate={new Date()}
                                                                    minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                                                                    locale={`${language}-${country == 'AUS' ? 'AU' : country}`}
                                                                />
                                                            }
                                                        </div>
                                                        {this.validator.message(
                                                            "incidentDate",
                                                            this.state.tempDate,
                                                            "required|valid_date|future_date",
                                                            {
                                                                messages:
                                                                {
                                                                    required: translations.errors?.date || 'Incident date is required.',
                                                                }
                                                            }
                                                        )}
                                                    </Col>
                                                ) : (
                                                    // MOBILE VERSION USES NATIVE CALENDAR PICKER 
                                                    <Col className={classNames(styles.mw275, 'd-block')}>
                                                        <input
                                                            type="date"
                                                            aria-current="date"
                                                            aria-label={moment().format("MMM Do YYYY")}
                                                            className={classNames(formControl)}
                                                            defaultValue=""
                                                            name="incidentDate"
                                                            id="incidentDateInput"
                                                            value={this.state.tempDate}
                                                            onChange={this.handleCleaveDate} required max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                                            min={moment(new Date().setFullYear(new Date().getFullYear() - 1)).format('YYYY-MM-DD')}
                                                        />
                                                        {this.validator.message(
                                                            "incidentDate",
                                                            this.state.tempDate,
                                                            "required|valid_date|future_date",
                                                            {
                                                                messages:
                                                                {
                                                                    required: translations.errors?.date || 'Incident date is required.',
                                                                }
                                                            }
                                                        )}
                                                    </Col>
                                                )}
                                            </Row>
                                        </FormGroup>
                                    </div>
                                    <div id="incidentDescription">
                                        <FormGroup className={!incidentDescription && validationFired ? "form-error" : ""}>
                                            <Label
                                                for="incidentDescInput"
                                                className={classNames(styles.cardTitle, !this.state.incidentDescription && validationFired && textError)}
                                            >
                                                {translations.description || 'Please provide a brief description of the issue.'}
                                                <span aria-hidden="true" className="required-indicator">*</span>
                                            </Label>
                                            <Row className="formBlock">
                                                <Col md="10" lg="9">
                                                    <IncidentDesc
                                                        handleChange={this.handleChange}
                                                        incidentDescription={this.state.incidentDescription}
                                                        charsRemainingPrefix={translations?.charsRemainingPrefix}
                                                        charsRemainingSuffix={translations?.charsRemainingSuffix}
                                                        charRemainingText={translations?.descriptionCharactersRemaining}
                                                        placeHolder={translations?.descriptionPlaceholder}
                                                        maxLength={250}
                                                        language={language}
                                                    />
                                                    {this.validator.message(
                                                        "incidentDescription",
                                                        this.state.incidentDescription,
                                                        "required",
                                                        {
                                                            messages:
                                                            {
                                                                required: translations.errors?.description || 'Incident description is required.',
                                                            }
                                                        }
                                                    )}
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </div>
                                    <Row id="affirm" className="formBlock">
                                        <Col>
                                            <div className="checkbox scriptBox">
                                                <Input
                                                    className="mr-2"
                                                    type="checkbox"
                                                    id={"affirmStatement" + this.props.claimId}
                                                    name="affirmationStatement"
                                                    onChange={this.affirmationChange}
                                                    tabIndex="0" checked={this.state.affirmed === "affirmed"}
                                                    aria-required="true"
                                                    className={!this.state.affirmed && validationFired && formError}
                                                />
                                                <Label
                                                    for={"affirmStatement" + this.props.claimId}
                                                    className={`checkboxLabel ${isChileApple ? 'southbridge' : ''}`}
                                                >
                                                    {getAffirmation()}
                                                </Label>
                                                {this.validator.message(
                                                    "affirmationStatement",
                                                    this.state.affirmed,
                                                    "affirmation",
                                                    {
                                                        messages:
                                                        {
                                                            affirmation: translations.errors?.affirmation || 'Please affirm the information provided is true and accurate.',
                                                        }
                                                    }
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <IntakeLossButtons
                                        // PRIMARY BUTTON PROPS
                                        buttonText={translations.continue || "Continue"}
                                        onButtonClick={this.continue}
                                        buttonDisabled={this.state.continuing}
                                        buttonSpinning={this.state.continuing}
                                        buttonAriaLabel={translations.intakeContinueAria || "Click continue to proceed to the shipping details step"}
                                        // FIRST LINK UNDER BUTTON
                                        showLink1={true}
                                        onLink1Click={this.onSaveForLaterAction}
                                        link1AriaLabel={translations.saveBtnAria || "Opens a modal to save your claim"}
                                        link1Text={translations.save || "Save For Later"}
                                        link1Id="later-id"
                                        // CANCEL LINK PROPS CONTROL CANCEL MODAL
                                        saveClaimClickActionFromWithinCancelModal={this.onSaveForLaterAction}
                                        caseNumberToCancel={this.state.caseNumber}
                                        claimNumberToCancel={this.state.claimNumber}
                                    />

                                    {this.state.showConfirmation &&
                                        <SaveModal
                                            isOpen={this.state.showConfirmation}
                                            aria-label={translations.saveBtnAria || "Opens a modal to save your claim"}
                                            showConfirmation={this.state.showConfirmation}
                                            history={this.props.history}
                                            continuing={this.state.isSaveForLater}
                                            onCancel={this.onSaveCancel}
                                            onSaveForLaterAction={this.saveForLater}
                                            client={"apple"}
                                        />
                                    }
                                </Row>
                            </>
                        )
                    }}
                </UseTranslationInClassComponent>
            </DocumentTitle>
        );
    }
}

Entitlement.contextType = ContractContext;

