import { React } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from './AppleCookiePolicyModal.module.css'

const AppleCookiePolicyModal = ({
    showCookiePolicyModal,
    onClose
}) => {

    return (
        <>
            <Modal 
                isOpen={showCookiePolicyModal} 
                aria-modal="true" 
                aria-labelledBy="cookiePolicy" 
                className="md"
                toggle={onClose}
            >
                <ModalHeader className="noLine justify-content-center pb-0">
                <h2 className='mb-0'>
                    Cookie Policy
                </h2>
                </ModalHeader>
                <ModalBody className={styles.modalBodyScroll}>
                    <p>
                        This Cookie Policy describes the cookies and similar technologies that are used on this website 
                        (“Site”) operated by American International Group (“AIG”).  For more information about our privacy 
                        practices, please refer to Privacy Policy.
                    </p>
                    <p>What cookies are used on our website?</p>
                    <p>
                        Cookies are pieces of information stored on your device about your visit and use of the Site. 
                        Cookies allow us to recognize your device and to collect information such as internet browser type, 
                        time spent on the AIG Site, pages visited, language preferences. We may use cookies, pixels and other 
                        similar online tracking technologies (collectively, “cookies”) to analyze your use of the website, 
                        personalize your experience, or support advertising initiatives. We may engage vendors, partners, 
                        or third parties to use cookies on the Site, and third parties may collect information about 
                        your use of the AIG Site.
                    </p>
                    <p>
                        Certain cookies are essential, which are necessary to make the website function and will be loaded 
                        onto your browser automatically.
                    </p>  
                    <p>
                        Other cookies that are not essential may include performance or analytics cookies that evaluate and 
                        improve the performance of the Site, advertising cookies that remember that you have visited the Site 
                        and may show you a tailored advertisement on another website, and social media cookies that enable you 
                        to share content on the Site. Certain of these cookies may collect information that reveals your 
                        location. You can modify these cookies by clicking the link at the bottom of this page.
                    </p>
                    <p>How are cookies being used?</p>
                    <p>
                        We may use cookies so that our website works and to make sure you get the best experience. We may 
                        use the information for security purposes, to facilitate navigation, to display information more 
                        effectively, or to personalize your experience while using the Site. In addition, we may use the 
                        information to gather statistical information about the usage of the Site in order to understand 
                        how they are used, continually improve their design and functionality, and assist us with resolving 
                        questions about them.  Cookies further allow us to present to you the advertisements or offers 
                        that are most likely to appeal to you. We may also use cookies to track your responses to our 
                        advertisements and we may use cookies or other files to track your use of other websites.
                    </p>
                    <p>How you can manage cookies</p>
                    <p>
                        You can manage cookies by changing your cookie preferences by clicking the link at the bottom of this page. 
                        You can find more information about cookies and how to disable cookies within your browser settings at 
                        www.allaboutcookies.org.
                    </p>
                    <p>Do Not Track (DNT) Signals</p>
                    <p>We do not respond to do-not-track signals at this time.</p>
                </ModalBody>
                <ModalFooter className="linkBtnLayout">
                    <button className="btn btn-secondary" onClick={onClose}>
                        Close
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default AppleCookiePolicyModal;