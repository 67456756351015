import React, { Component } from 'react';
import {
    Container, Row, Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import styles from './landingApple.module.css';
import classNames from 'classnames';
import { ReactComponent as AppleLogo } from '../../../assets/images/Vendor/Apple/Apple-Logo.svg'
import SimpleReactValidator from 'simple-react-validator';
import { callAdobe, updatePartnerCase } from '../../../helpers';
import DocumentTitle from 'react-document-title';
import { UseTranslationInClassComponent } from '../../../components/contexts/LanguageTranslation/UseTranslationInClassComponent'
import SpinnerButton from '../../../components/shared/SpinnerButton/SpinnerButton'
import StandardInput from '../../../components/shared/StandardInput/StandardInput'
import { appleServiceNumbers } from '../../../components/_BrandingProvider/brandContent';
import CallLink from '../../../components/shared/PhoneNumberUtility/CallLink';
import SuspendedLanding from './SuspendedLanding';
import { CombinedLangContractContext } from '../../../components/contexts/ProviderLangContractContext';
import { LanguageContext } from '../../../components/contexts/LanguageTranslation/LanguageContext';
import { saveLanguage } from '../../../components/Global/helpers';
import PhoneNumberInput from '../../../components/shared/PhoneNumberUtility/PhoneNumberInput';


let radioButton = "radioButton";
let carrier = styles.carrier;

export default class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caseNumber: typeof this.props.match.params.caseId == "undefined" || this.props.match.params.caseId == null ? null : this.props.match.params.caseId,
            zip: typeof this.props.match.params.zip == "undefined" || this.props.match.params.zip == null ? null : this.props.match.params.zip,
            email: typeof this.props.match.params?.email == "undefined" || this.props.match.params?.email == null ? null : this.props.match.params?.email,
            contactPhone: typeof this.props.match.params.contactPhone == "undefined" || this.props.match.params.contactPhone == null ? null : this.props.match.params.contactPhone,
            continuing: false,
            caseModel: null,
            claim: null,
            found: true,
            isRecurring: true,
            carrier: null,
            contract: null,
            validated: false,
            validationFired: false,
            rendering: true,
            clientId: "apple",
            pageTitle: 'File or Track Claim | AIG AppleCare+ with Theft and Loss Claims',
            existingClaim: null,
            countryCode: "",
            paymentSuspended: false,
            partyId: null,
            hasExistingClaim: false
        }


        this.handleTextInput = this.handleTextInput.bind(this);
        this.handleCarrierChange = this.handleCarrierChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.continue = this.continue.bind(this);
        this.continueNonHandler = this.continueNonHandler.bind(this);
        this.search = this.search.bind(this);
        this.validator = new SimpleReactValidator({
            messages: {
                email: "Please enter a valid email address.",
                required: ':attribute is required.'
            },
            validators: {
                valid_phone: {
                    message: 'Please enter a valid phone number.',
                    rule: (val, params, validator) => {
                        return !new RegExp("^([0-9])\\1+$").test(val.match(/\d+/g).join(''))
                    }
                }
            },
            element: message => <div className="errorMsg" aria-live="polite">{message.charAt(0).toUpperCase() + message.slice(1)}</div>
        });
    }

    handleTextInput = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value ? value : null
        });
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let { name, value, id } = e.target;
            let checked = false;

            switch (id) {
                case "reach-skip-nav":
                    checked = document.getElementById('carrier1').checked;
                    break;
                case "carrier2Div":
                    checked = document.getElementById('carrier2').checked;
                    break;
                case "carrier3Div":
                    checked = document.getElementById('carrier3').checked;
                    break;
                case "carrier4Div":
                    checked = document.getElementById('carrier4').checked;
                    break;
                case "otherCarrierCheckboxDiv":
                    checked = document.getElementById('otherCarrier').checked;
                    break;
                default:
                    checked = e.target.checked;
            }

            if (checked === false) {
                switch (id) {
                    case "otherCarrier":
                    case "otherCarrierCheckboxDiv":
                        value = "other";
                        break;
                    case "reach-skip-nav":
                        value = "att";
                        break;
                    case "carrier2Div":
                        value = "sprint";
                        break;
                    case "carrier3Div":
                        value = "tmobile";
                        break;
                    case "carrier4Div":
                        value = "verizon";
                        break;
                }
            } else {
                value = null;
            }

            this.setState({
                carrier: value,
                checked: true,
                validated: false,
            });
        }
    }
    handleCarrierChange = e => {
        let { name, value } = e.target;
        let checked = e.target.checked;

        if (value === "on") {
            if (checked) {
                value = "other";
            }
            else {
                value = null;
            }
        }

        this.setState({
            carrier: value,
            validated: false,
        });
    }

    getLangCode = (language, countryCode) => {
        let langCode = language;

        if (language.toLowerCase() === 'cl') {
            langCode = 'es';
        }
        if (language.toLowerCase() === 'vn') {
            langCode = 'vi';
        }
        if (language.toLowerCase() === 'at' || language.toLowerCase() === 'sg') {
            langCode = 'de';
        }
        return langCode?.toLowerCase() + '-' + countryCode?.toLowerCase();
    }

    search = (e) => {
        try {
            e.preventDefault();
        }
        catch (err) {
            console.log(e);
        }

        this.setState({
            zip: this.state.zip === null ? '' : this.state.zip,
            caseNumber: this.state.caseNumber === null ? '' : this.state.caseNumber,
            email: this.state.email === null ? '' : this.state.email,
            contactPhone: this.state.contactPhone === null ? '' : this.state.contactPhone,
        });

        if (this.state.hasExistingClaim || this.validator.allValid()) {
            this.setState({ continuing: true });
            // ajax call
            fetch('api/claim/CaseClaimSearch', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CaseNumber: this.state.caseNumber,
                    ClientId: this.state.clientId,
                    ZipCode: this.state.zip,
                    Email: this.state.email,
                    ContactPhone: this.state.contactPhone
                })
            })
                .then(res => {
                    if (res.status === 204) {
                        this.setState({ found: false });
                    }
                    else if (res.status === 200) {
                        res.json()
                            .then(data => {
                                if (data != null && data.claim != null && data.claim.claimId != null) {
                                    this.setState({ claim: data.claim });
                                    this.setState({ paymentSuspended: data.paymentSuspended })
                                    const country = data.countryCode ?? "US";
                                    const formattedCountry = country.toUpperCase()
                                    sessionStorage.setItem('country', formattedCountry)
                                    this.setState({
                                        countryCode: formattedCountry
                                    });
                                    this.context.languageContext.updateCountry(formattedCountry);
                                    this.context.contractContext.updateContract(data.contract);
                                    if (this.context.languageContext.languageBeforeLogin) {
                                        const updatedLangCode = this.getLangCode(this.context.languageContext.languageBeforeLogin, country);

                                        saveLanguage(data.contract.contractId, updatedLangCode, 'apple')
                                    }

                                    let entitleObject = {
                                        caseNumber: data.claim.caseNumber,
                                        clientId: "apple",
                                        claimId: data.claim.claimId,
                                        claimNumber: data.claim.claimNumber,
                                        incidentDescription: data.claim.incidentDescription,
                                        incidentDate: data.claim.incidentDate === undefined ? data.claim.lossDate : data.claim.incidentDate,
                                        caseNumber: data.claim.clientClaimNumber,
                                        countryCode: data.countryCode ?? "us",
                                        contract: data.contract,
                                        party: data.contract.party,
                                        shipToCountryCode: data.shipToCountryCode
                                    }
                                    if (this.state.paymentSuspended == true && country.toLowerCase() == "us") {
                                        this.props.history.push(SuspendedLanding);
                                    }
                                    else if (data.claim.claimStatusId == 6 || data.claim.claimStatusId == 8) {
                                        fetch(`api/contract/GetByContractAssetId/${data.claim.contractAssetId}/` + "apple", {
                                            method: 'Get',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            }
                                        }).then(res => {
                                            if (res.ok) {
                                                res.json().then(cdata => {
                                                    let contract = cdata;
                                                    let party = contract.party;
                                                    // let contractAsset = this.state.contract;
                                                    let riskProfile = contract.riskProfile.riskProfileConfigurationModel;
                                                    let deductible = riskProfile.find(item => { return item.pricingAttributeId === 19 }).value;
                                                    let featureIndex = data.claim.featureClaimModel.length - 1;
                                                    let contractAssetCoverageId = typeof data.claim.featureClaimModel[featureIndex] === "undefined" ? null : data.claim.featureClaimModel[featureIndex].contractAssetCoverageId;
                                                    let entitle = {
                                                        caseNumber: data.claim.caseNumber,
                                                        clientId: "apple",
                                                        contract: cdata,
                                                        contractAsset: cdata.contractAssetModel[0],
                                                        claimId: data.claim.claimId,
                                                        claimNumber: data.claim.claimNumber,
                                                        partyPhone: this.state.contactPhone,
                                                        party: party,
                                                        deductible: deductible,
                                                        incidentDescription: data.claim.incidentDescription,
                                                        incidentDate: data.claim.incidentDate === undefined ? data.claim.lossDate : data.claim.incidentDate,
                                                        claimSetupDate: data.claim.claimSetupDate,
                                                        coverageId: contractAssetCoverageId,
                                                        caseNumber: data.claim.clientClaimNumber,
                                                        countryCode: data.countryCode ?? "us",
                                                        shipToCountryCode: data.shipToCountryCode
                                                    }
                                                    if (data.claim.claimConfigurationModel.find(cc => cc.claimAttributeId == 5).value == 1) {
                                                        // Redirect to Entitlement
                                                        this.props.history.push('/vendor/apple/entitlement', {
                                                            entitlementDetails: {
                                                                clientId: "apple",
                                                                contract: cdata,
                                                                contractAsset: cdata.contractAssetModel[0],
                                                                party: party,
                                                                deductible: deductible,
                                                                claimId: data.claim.claimId,
                                                                claimNumber: data.claim.claimNumber,
                                                                caseNumber: data.claim.clientClaimNumber,
                                                                coverageId: contractAssetCoverageId,
                                                                countryCode: data.countryCode ?? "us",
                                                                shipToCountryCode: data.shipToCountryCode
                                                            }, claim: data.claim
                                                        });
                                                    }
                                                    else if (data.claim.claimConfigurationModel.find(cc => cc.claimAttributeId == 5).value == 2) {
                                                        this.props.history.push('/vendor/apple/verifyinfo', {
                                                            entitlementDetails: entitle, claim: data.claim
                                                        });
                                                    }
                                                    else if (data.claim.claimConfigurationModel.find(cc => cc.claimAttributeId == 5).value == 3) {
                                                        let mailingAddress = party.partyAddressModel.find(p => p.addressTypeId == 2);
                                                        let shippingAddress = party.partyAddressModel.find(p => p.addressTypeId == 3); // 3- shipping Address Type
                                                        shippingAddress = shippingAddress ?? mailingAddress;
                                                        this.props.history.push('/vendor/apple/payment', {
                                                            entitlementDetails: {
                                                                clientId: "apple",
                                                                caseNumber: data.claim.clientClaimNumber,
                                                                partyId: contract.party.partyId,
                                                                partyStatusId: party.partyStatusId,
                                                                firstName: party.firstName,
                                                                lastName: party.lastName,
                                                                partyName: party.firstName + " " + party.lastName,
                                                                partyAddressModel: party.partyAddressModel,
                                                                partyPhoneModel: party.partyPhoneModel,
                                                                partyEmailModel: party.partyEmailModel,
                                                                contract: cdata,
                                                                contractAsset: cdata.contractAssetModel[0],
                                                                claimId: data.claim.claimId,
                                                                claimNumber: data.claim.claimNumber,
                                                                party: party,
                                                                deductible: deductible,
                                                                incidentDescription: data.claim.incidentDescription,
                                                                incidentDate: data.claim.incidentDate === undefined ? data.claim.lossDate : data.claim.incidentDate,
                                                                claimSetupDate: data.claim.claimSetupDate,
                                                                coverageId: contractAssetCoverageId,
                                                                mailingAddress: mailingAddress.address1,
                                                                mailingAddress2: mailingAddress.address2,
                                                                city: mailingAddress.city,
                                                                state: mailingAddress.state,
                                                                stateId: mailingAddress.stateId,
                                                                zip: mailingAddress.zip,
                                                                contactPhone: party.partyPhoneModel.find(pp => pp.phoneType = 2).number,
                                                                email: party.partyEmailModel[0].address,
                                                                shippingAddress: shippingAddress,
                                                                countryCode: data.countryCode ?? "us",
                                                                shipToCountryCode: data.shipToCountryCode
                                                            }, claim: data.claim
                                                        });
                                                    }
                                                    else if (data.claim.claimConfigurationModel.find(cc => cc.claimAttributeId == 5).value == 4) {
                                                        let mailingAddress = party.partyAddressModel.find(p => p.addressTypeId == 2);
                                                        let shippingAddress = party.partyAddressModel.find(p => p.addressTypeId == 3); // 3- shipping Address Type
                                                        shippingAddress = shippingAddress ?? mailingAddress;
                                                        this.props.history.push('/vendor/apple/uploaddocs', {
                                                            entitlementDetails: {
                                                                clientId: "apple",
                                                                caseNumber: data.claim.clientClaimNumber,
                                                                partyId: contract.party.partyId,
                                                                partyStatusId: party.partyStatusId,
                                                                firstName: party.firstName,
                                                                lastName: party.lastName,
                                                                partyName: party.firstName + " " + party.lastName,
                                                                partyAddressModel: party.partyAddressModel,
                                                                partyPhoneModel: party.partyPhoneModel,
                                                                partyEmailModel: party.partyEmailModel,
                                                                contract: cdata,
                                                                contractAsset: cdata.contractAssetModel[0],
                                                                claimId: data.claim.claimId,
                                                                claimNumber: data.claim.claimNumber,
                                                                party: party,
                                                                deductible: deductible,
                                                                incidentDescription: data.claim.incidentDescription,
                                                                incidentDate: data.claim.incidentDate === undefined ? data.claim.lossDate : data.claim.incidentDate,
                                                                coverageId: contractAssetCoverageId,
                                                                mailingAddress: mailingAddress.address1,
                                                                mailingAddress2: mailingAddress.address2,
                                                                city: mailingAddress.city,
                                                                state: mailingAddress.state,
                                                                zip: mailingAddress.zip,
                                                                contactPhone: party.partyPhoneModel.find(pp => pp.phoneType = 2).number,
                                                                email: party.partyEmailModel[0].address,
                                                                shippingAddress: shippingAddress,
                                                                countryCode: data.countryCode ?? "us",
                                                                shipToCountryCode: data.shipToCountryCode
                                                            }, claim: data.claim
                                                        });
                                                    }
                                                    else {
                                                        if (!data.claim.hasDocuments) {
                                                            // Redirect to upload documents SEU-712
                                                            this.props.history.push("/vendor/apple/claimoverview/", { isDocUploaded: false, isUnderReview: false, isValidClaim: false, claim: data.claim, claimId: data.claim.claimId, entitlementDetails: entitle, clientId: "apple", caseNumber: data.claim.clientClaimNumber, claimNumber: data.claim.claimNumber, isDeductibleUnsuccessful: data.isDeductibleUnsuccessful, isPendingReview: data.isPendingReview, isClaimDenied: data.isClaimDenied });
                                                        }
                                                        else if (data.isPendingClaim) {
                                                            // Redirect to scripting page SEU-712
                                                            this.props.history.push("/vendor/apple/claimoverview/", { isDocUploaded: true, isUnderReview: true, isValidClaim: false, claim: data.claim, claimId: data.claim.claimId, entitlementDetails: entitle, clientId: "apple", caseNumber: data.claim.clientClaimNumber, claimNumber: data.claim.claimNumber, isDeductibleUnsuccessful: data.isDeductibleUnsuccessful, isPendingReview: data.isPendingReview, isClaimDenied: data.isClaimDenied });
                                                        }
                                                        else if (!data.isValidClaim) {
                                                            // Redirect to Failed B. Rules page SEU-713
                                                            this.props.history.push("/vendor/apple/claimoverview/", { isDocUploaded: true, isUnderReview: false, isValidClaim: false, claim: data.claim, claimId: data.claim.claimId, entitlementDetails: entitle, clientId: "apple", caseNumber: data.claim.clientClaimNumber, claimNumber: data.claim.claimNumber, isDeductibleUnsuccessful: data.isDeductibleUnsuccessful, isPendingReview: data.isPendingReview, isClaimDenied: data.isClaimDenied });
                                                        }
                                                        else {
                                                            // Redirect to Claims Overview SEU-613
                                                            this.props.history.push("/vendor/apple/claimoverview/", { isDocUploaded: true, isUnderReview: false, isValidClaim: true, claim: data.claim, claimId: data.claim.claimId, entitlementDetails: entitle, clientId: "apple", caseNumber: data.claim.clientClaimNumber, claimNumber: data.claim.claimNumber, isDeductibleUnsuccessful: data.isDeductibleUnsuccessful, isPendingReview: data.isPendingReview, isClaimDenied: data.isClaimDenied });
                                                        }
                                                    }
                                                });
                                            }
                                        });
                                    }
                                    else if (data.additionalReview) {
                                        // Redirect to upload documents SEU-1443 Additional documents required
                                        this.props.history.push("/vendor/apple/claimoverview/", { additionalReview: true, isDocUploaded: false, isUnderReview: false, isValidClaim: false, claim: data.claim, claimId: data.claim.claimId, entitlementDetails: entitleObject, clientId: "apple", caseNumber: data.claim.clientClaimNumber, claimNumber: data.claim.claimNumber, ccNumber: data.creditCardNumber, ccType: data.creditCardType, isDeductibleUnsuccessful: data.isDeductibleUnsuccessful, isPendingReview: data.isPendingReview, isClaimDenied: data.isClaimDenied });
                                    }
                                    else if (data.claim.claimStatusId == 3) // partial claim
                                    {
                                        // open carrier selection page
                                        this.setState({
                                            rendering: false,
                                            isRecurring: false,
                                            contract: data.contractAsset,
                                            existingClaim: data.claim.claimId
                                        });
                                    }
                                    else if (!(data.hasDocuments && !data.documentResubmissionRequested)) {
                                        // Redirect to upload documents SEU-712
                                        this.props.history.push("/vendor/apple/claimoverview/", { additionalReview: false, isDocUploaded: false, isUnderReview: false, isValidClaim: false, claim: data.claim, claimId: data.claim.claimId, entitlementDetails: entitleObject, clientId: "apple", caseNumber: data.claim.clientClaimNumber, claimNumber: data.claim.claimNumber, ccNumber: data.creditCardNumber, ccType: data.creditCardType, isDeductibleUnsuccessful: data.isDeductibleUnsuccessful, isPendingReview: data.isPendingReview, isClaimDenied: data.isClaimDenied });
                                    }
                                    else if (data.isPendingClaim) {
                                        // Redirect to scripting page SEU-712
                                        this.props.history.push("/vendor/apple/claimoverview/", { additionalReview: false, isDocUploaded: true, isUnderReview: true, isValidClaim: false, claim: data.claim, claimId: data.claim.claimId, entitlementDetails: entitleObject, clientId: "apple", caseNumber: data.claim.clientClaimNumber, claimNumber: data.claim.claimNumber, ccNumber: data.creditCardNumber, ccType: data.creditCardType, isDeductibleUnsuccessful: data.isDeductibleUnsuccessful, isPendingReview: data.isPendingReview, isClaimDenied: data.isClaimDenied });
                                    }
                                    else if (!data.isValidClaim) {
                                        // Redirect to Failed B. Rules page SEU-713
                                        this.props.history.push("/vendor/apple/claimoverview/", { additionalReview: false, isDocUploaded: true, isUnderReview: false, isValidClaim: false, claim: data.claim, claimId: data.claim.claimId, entitlementDetails: entitleObject, clientId: "apple", caseNumber: data.claim.clientClaimNumber, claimNumber: data.claim.claimNumber, isDeductibleUnsuccessful: data.isDeductibleUnsuccessful, isPendingReview: data.isPendingReview, isClaimDenied: data.isClaimDenied });
                                    }
                                    else {
                                        // Redirect to Claims Overview SEU-613
                                        this.props.history.push("/vendor/apple/claimoverview/", { additionalReview: false, isDocUploaded: true, isUnderReview: false, isValidClaim: true, claim: data.claim, claimId: data.claim.claimId, entitlementDetails: entitleObject, clientId: "apple", caseNumber: data.claim.clientClaimNumber, claimNumber: data.claim.claimNumber, isDeductibleUnsuccessful: data.isDeductibleUnsuccessful, isPendingReview: data.isPendingReview, isClaimDenied: data.isClaimDenied });
                                    }
                                }
                                else {
                                    this.setState({ found: false });
                                }
                            })
                    }
                    else {
                        this.setState({ found: false });
                    }

                    this.setState({ continuing: false });
                })
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    continueNonHandler = () => {

        if (this.state.countryCode != null) {
            this.setState({ continuing: true });

            if (this.state.existingClaim == null) {

                fetch('api/claim', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        contractAssetId: parseInt(this.state.contract.contractAssetId),
                        clientClaimNumber: this.state.caseNumber,
                        clientId: "apple",
                        claimNumber: this.state.claimNumber,
                        clientId: this.state.clientId
                    })
                }).then(res => {
                    if (res.ok) {
                        res.json().then(claim => {
                            let claimId = claim.claimId;
                            let claimNumber = claim.claimNumber;

                            // let's go ahead and update the partner case
                            // update partner case
                            updatePartnerCase(claim.claimNumber, "PCCU", this.state.clientId);

                            // Get Contract
                            fetch(`api/contract/${this.state.contract.contractId}/${this.state.clientId}`, {
                                method: 'Get',
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }).then(res => {
                                if (res.ok) {
                                    res.json().then(data => {
                                        if (typeof data != "undefined") {
                                            this.setState({ continuing: false });

                                            let contract = data;
                                            let party = contract.party;
                                            let contractAsset = this.state.contract;
                                            let riskProfile = contract.riskProfile.riskProfileConfigurationModel;
                                            let deductible = riskProfile.find(item => { return item.pricingAttributeId === 19 }).value;

                                            this.context.contractContext.updateContract(contract);
                                            if (this.context.languageContext.languageBeforeLogin) {
                                                const updatedLangCode = this.getLangCode(this.context.languageContext.languageBeforeLogin, this.state.countryCode || 'US');

                                                saveLanguage(contract.contractId, updatedLangCode, 'apple')
                                            }
                                            // Redirect to Entitlement
                                            this.props.history.push('/vendor/apple/entitlement', {
                                                entitlementDetails: {
                                                    contract: data,
                                                    contractAsset: this.state.contract,
                                                    partyPhone: this.state.contactPhone,
                                                    party: party,
                                                    deductible: deductible,
                                                    claimId: claimId,
                                                    claimNumber: claimNumber,
                                                    caseNumber: this.state.caseNumber,
                                                    coverageId: "",
                                                    clientId: this.state.clientId,
                                                    countryCode: this.state.countryCode ?? "us"
                                                }, claim
                                            });
                                        }
                                        else {
                                            this.setState({ continuing: false });
                                        }
                                    });
                                }
                            });
                        });
                    }
                });
            }
            else {
                // Get Contract
                fetch(`api/contract/${this.state.contract.contractId}/${this.state.clientId}`, {
                    method: 'Get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    if (res.ok) {
                        res.json().then(data => {
                            if (typeof data != "undefined") {
                                this.setState({ continuing: false });

                                let claim = this.state.claim;
                                let contract = data;
                                let party = contract.party;
                                let contractAsset = this.state.contract;
                                let riskProfile = contract.riskProfile.riskProfileConfigurationModel;
                                let deductible = riskProfile.find(item => { return item.pricingAttributeId === 19 }).value;

                                // Redirect to Entitlement
                                this.props.history.push('/vendor/apple/entitlement', {
                                    entitlementDetails: {
                                        contract: data,
                                        contractAsset: this.state.contract,
                                        party: party,
                                        deductible: deductible,
                                        claimId: this.state.claim?.claimId,
                                        claimNumber: this.state.claim?.claimNumber,
                                        caseNumber: this.state.caseNumber,
                                        coverageId: "",
                                        clientId: this.state.clientId,
                                        countryCode: this.state.countryCode ?? "us"
                                    }, claim
                                });
                            }
                            else {
                                this.setState({ continuing: false });
                            }
                        });
                    }
                });
            }
        }
        else {
            this.setState({
                validated: true
            });
        }
    }
    continue = (e) => {
        e.preventDefault();
        this.setState({ validationFired: true })
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return;
        }
        this.continueNonHandler();
        let party = this.state.contract.contractModel.partyId;
        let partyEmail = this.state.email;
        let partyPhone = this.state.contactPhone;
        let emailModel = [];
        let phoneModel = [];
        let email = { address: partyEmail, contactPhone: partyPhone, createdBy: "CustomerPortal", partyId: party, IsPrimary: true };
        let phone = { partyId: party, phoneTypeId: 2, number: partyPhone, IsPrimary: true };
        emailModel.push(email);
        phoneModel.push(phone);
        fetch('api/party/UpdatePartyPhoneAndEmail/'.concat(party, "/")
            .concat(this.state.clientId),
            {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                    {
                        partyId: party,
                        partyPhoneModel: phoneModel,
                        partyEmailModel: emailModel
                    }
                )
            });
    }




    componentDidMount() {
        fetch('api/session/GetPostedAppleCaseModel')
            .then(res => res.json())
            .then(data => {
                this.context.contractContext.updateContract(data?.contractAsset?.contractModel);
                this.setState({
                    caseModel: data,
                    contract: data.contractAsset,
                    caseNumber: data.caseId,
                    hasExistingClaim: data.hasExistingClaim,
                    email: data.emailAddress,
                    isRecurring: false,
                    rendering: false
                });
            }, (error) => {
                this.setState({
                    isRecurring: true,
                    rendering: false
                });
            }).then(() => fetch('api/session/GetPostedCountryCode')
                .then(res => {
                    if (res.status === 204) {
                        // When no handshake performed, thus no country.
                        if (this.state.isRecurring) {
                            callAdobe({
                                pagePath: '/vendor/apple/search',
                                pageName: 'Plan Lookup',
                                //country: formattedCountry,
                                language: this.context.languageContext?.language,
                                currency: this.context.languageContext?.countryVars?.currency?.abbreviation,
                                caseId: this.props.match?.params?.caseId
                            })
                        } else {
                            callAdobe({
                                pagePath: '/vendor/apple/landing',
                                pageName: 'Landing',
                                //country: formattedCountry,
                                language: this.context.languageContext?.language,
                                currency: this.context.languageContext?.countryVars?.currency?.abbreviation,
                                caseId: this.props.match?.params?.caseId
                            })
                        }
                    } else if (res.status === 200) {
                        res.text()
                            .then(data => {
                                const country = data;
                                const formattedCountry = data.toUpperCase()
                                this.setState({
                                    countryCode: formattedCountry
                                });
                                this.context.languageContext.updateCountry(formattedCountry);

                                if (this.state.isRecurring) {
                                    callAdobe({
                                        pagePath: '/vendor/apple/search',
                                        pageName: 'Plan Lookup',
                                        country: formattedCountry,
                                        language: this.context.languageContext?.language,
                                        currency: this.context.languageContext?.countryVars?.currency?.abbreviation,
                                        caseId: this.props.match?.params?.caseId
                                    })
                                } else {
                                    callAdobe({
                                        pagePath: '/vendor/apple/landing',
                                        pageName: 'Landing',
                                        country: formattedCountry,
                                        language: this.context.languageContext?.language,
                                        currency: this.context.languageContext?.countryVars?.currency?.abbreviation,
                                        caseId: this.props.match?.params?.caseId
                                    })
                                }

                                if (this.state.hasExistingClaim) {                                   
                                    return this.search(this);
                                }
                            })
                    }
                })
            )
    }

    render() {
        if (this.state.rendering) {
            return this.state.isRecurring ? < div /> : <div id="recurring_true" />
        }
        const { pageTitle } = this.state;
        let caseModel = this.state.caseModel;
        const {lang, country} = this.context
        const { contractContext: { data: { isChileApple } } } = this.context;

        return (
            <>
                <DocumentTitle title={pageTitle} >
                    <UseTranslationInClassComponent componentPathFromSrcFolder={'screens/Vendor/Apple/Landing'}>
                        {international => {
                            const { translations, country, englishVars: { center } } = international
                            return (
                                <Container className="contentWrapper">
                                    <Form className={styles.appleform}>
                                        <Row>
                                            <div className="col-12">
                                                {/* <img className={styles.applelogo} src="assets/images/Apple-Logo.svg" alt="Apple" /> */}
                                                <AppleLogo />
                                                <h1 className={isChileApple ? styles.landingTextSB : ""}>
                                                    {translations.title || 'Welcome to the AppleCare+'}
                                                    <br className="show-md-up" aria-hidden="true" />
                                                    {translations.titleLine2 || `with Theft and Loss Service ${center}`}
                                                </h1>
                                            </div>
                                        </Row>

                                        {(this.state.paymentSuspended && this.state.countryCode?.toLowerCase() == "us") ? (
                                            <SuspendedLanding pageTitle={this.state.pageTitle} />
                                        ) : this.state.isRecurring ? (
                                            <>
                                                <div class="w-100">
                                                    <Row className={styles.search}>
                                                        <Col md="6" lg="4" className="mx-auto">
                                                            <StandardInput
                                                                label={translations.caseNumber || 'Case Number'}
                                                                hasError={this.validator.message(
                                                                    "caseNumber",
                                                                    this.state.caseNumber,
                                                                    "required"
                                                                 )}
                                                                fieldName="caseNumber"
                                                                ariaLabelAndFieldId="caseNumber"
                                                                value={this.state.caseNumber}
                                                                onChange={this.handleTextInput}
                                                                validator={this.validator.message(
                                                                    "caseNumber",
                                                                    this.state.caseNumber,
                                                                    "required",
                                                                    {
                                                                        messages: {
                                                                            required: translations.errors?.caseNumberRequired || "Case number is required."
                                                                        }
                                                                    }
                                                                )}
                                                            />
                                                            <StandardInput
                                                                label={translations.email || 'Email Address'}
                                                                hasError={this.validator.message(
                                                                    "email",
                                                                    this.state.email,
                                                                    "required|email"
                                                                )}
                                                                fieldName="email"
                                                                ariaLabelAndFieldId="email"
                                                                value={this.state.email}
                                                                onChange={this.handleTextInput}
                                                                maxLength={100}
                                                                validator={this.validator.message(
                                                                    "email",
                                                                    this.state.email,
                                                                    "required|email",
                                                                    {
                                                                        messages: {
                                                                            required: translations.errors?.emailRequired || "Email is required.",
                                                                            email: translations.errors?.emailInvalid || 'Please enter a valid email address.'
                                                                        }
                                                                    }
                                                                )}
                                                                />                                                                
                                                            {!this.state.found &&
                                                                <div className="validationSummary" role="alert">
                                                                    {translations.errors?.notFound || 'Sorry, we couldn’t find your plan. Please try again or call us at '}
                                                                    <CallLink phone={appleServiceNumbers.customerService[country]} />.
                                                                    {/* <a class="callLink" href="tel:866-258-7419">866-258-7419</a> */}
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <SpinnerButton
                                                    type='primary'
                                                    onClick={this.search}
                                                    text={translations.continueButton || 'Continue'}
                                                    spinning={this.state.continuing}
                                                    ariaLabel={translations.continueButtonAria || "Click continue to file or track your claim"}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <div class="w-100">
                                                    <h6 className='cardTitle'>
                                                        {translations?.text || 'Please provide your contact details in the event we need to reach you regarding your claim.'}
                                                    </h6>
                                                    <Row className={styles.search}>
                                                        <Col md="6" lg="4" className="mx-auto">
                                                            <StandardInput
                                                                label={translations.email || 'Email Address'}
                                                                hasError={this.validator.message(
                                                                    "email",
                                                                    this.state.email,
                                                                    "required|email")}
                                                                fieldName="email"
                                                                ariaLabelAndFieldId="email"
                                                                value={this.state.email}
                                                                maxLength={100}
                                                                onChange={this.handleTextInput}
                                                                validator={this.validator.message(
                                                                    "email",
                                                                    this.state.email,
                                                                    "required|email",
                                                                    {
                                                                        messages: {
                                                                            required: translations.errors?.emailRequired || "Email is required.",
                                                                            email: translations.errors?.emailInvalid || 'Please enter a valid email address.'
                                                                        }
                                                                    }
                                                                )}
                                                            />
                                                            <PhoneNumberInput
                                                                fieldName="contactPhone"
                                                                label={translations.contactPhone || "Phone Number"}
                                                                value={this.state.contactPhone}
                                                                onChange={this.handleTextInput}
                                                                validationFired={this.state.validationFired}
                                                                error={this.validator.message(
                                                                    "contactPhone",
                                                                    this.state.contactPhone,
                                                                    `required|valid_phone${country === 'US' ? '|phone' : ''}|min:6`,
                                                                    {
                                                                        messages: {
                                                                            valid_phone: translations.errors?.phoneInvalid || "The phone number must be a valid number.",
                                                                            required: translations.errors?.phoneRequired || "Phone number is required.",
                                                                            phone: translations.errors?.phoneInvalid || "The phone number must be a valid number.",
                                                                            min: translations.errors?.phoneInvalid || "The phone number must be a valid number.",
                                                                        }
                                                                    }
                                                                )}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <SpinnerButton
                                                    type='primary'
                                                    onClick={this.continue}
                                                    text={translations.continueButton || 'Continue'}
                                                    spinning={this.state.continuing}
                                                    ariaLabel={translations.continueBtnAria || "Click continue to proceed to what happened step"}
                                                />
                                            </>
                                        )}
                                    </Form>
                                </Container>
                            )
                        }}
                    </UseTranslationInClassComponent>
                </DocumentTitle>
            </>
        );
    }
}

Landing.contextType = CombinedLangContractContext